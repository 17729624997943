import { Injectable, NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
@NgModule()
export class SharedService {
  baseUrl: any;
  isLoading: boolean = false;
  riderData: any;
  shiftStatus = false;
  watchPosition: any;
  constructor(private toastController: ToastController,
    private loadingController: LoadingController,
    private sanitize: DomSanitizer) {
    this.baseUrl = "https://staging.onlineordering.mikronexus.com/index.php/" //staging
    // this.baseUrl="http://192.168.1.214/mknx-apis/index.php/"; //local
    // this.baseUrl="" //prod
  }
  public async presentToast(msg?, position?, color?, time?) {

    const toast = await this.toastController.create({
      animated: true,
      mode: 'ios',
      position: position ? position : 'bottom',
      color: color ? color : 'dark',
      cssClass: 'APP_toast',
      buttons: [
        {
          text: '',
          icon: 'close',
          side: 'end',
          cssClass: 'cancelBTN',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],

      duration: time ? time : 2000,
      message: msg ? msg : 'Please wait..',
    });
    toast.present();
  }

  async loader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // duration: 5000,
        message: '<img src="assets/img/loader.png" alt="loading...">',
        cssClass: 'custom_loading',
      })
      .then((a) => {
        a.present().then(() => {
          console.log('Loader Presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController
      .dismiss()
      .then(() => console.log('Loader dismissed'));
  }
  getmapImage(location) {
    console.log(location)
    if(typeof location !='string'){
      return ''
    }
    let loc = location.replace(/ /g, '+');
    let address = `https://maps.googleapis.com/maps/api/staticmap?center=${loc}&zoom=16&size=400x160&key=AIzaSyCDFSak5czCvHjYNhIg0puzcFVzWAc2TPw&q`;
    // console.log(address)
    let url = this.sanitize.bypassSecurityTrustResourceUrl(address);
    // console.log("location ",url);
    return url;
  }
}
