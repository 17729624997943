import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'assigned-orders',
    loadChildren: () => import('./orders/assigned-orders/assigned-orders.module').then( m => m.AssignedOrdersPageModule)
  },
  {
    path: 'active-orders',
    loadChildren: () => import('./orders/active-orders/active-orders.module').then( m => m.ActiveOrdersPageModule)
  },
  {
    path: 'all-orders',
    loadChildren: () => import('./orders/all-orders/all-orders.module').then( m => m.AllOrdersPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./orders/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'day-shift-report',
    loadChildren: () => import('./day-shift-report/day-shift-report.module').then( m => m.DayShiftReportPageModule)
  },
  {
    path: 'date-picker',
    loadChildren: () => import('./date-picker/date-picker.module').then( m => m.DatePickerPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
