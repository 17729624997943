import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SharedService } from './services/shared.service';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { ServerService } from './services/server.service';
import { BackgroundGeolocation, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationResponse } from '@awesome-cordova-plugins/background-geolocation/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  config: BackgroundGeolocationConfig = {
    desiredAccuracy: 10,
    stationaryRadius: 20,
    distanceFilter: 30,
    debug: false, //  enable this hear sounds for background-geolocation life-cycle.
    stopOnTerminate: true, // enable this to clear background location settings when the app terminates
  };
  constructor(
    private platform: Platform,
    private router: Router,
    private globals: SharedService,
    private background: BackgroundMode,
    private geoLocation: Geolocation,
    private server: ServerService,
    private backgroundGeolocation: BackgroundGeolocation
  ) {
    this.initApp();
  }

  private initApp() {
    this.platform.ready().then(() => {
      this.getLoginInfo();
      this.backgroundMode();
      this.backgroundActivity();
      setInterval(()=>{
      this.backgroundLocate();
      },10000)
      this.backgroundLocate();

      // this.backgroundLocate()



    })

  }
  backgroundLocate() {
    this.backgroundGeolocation.configure(this.config)
      .then(() => {

        this.backgroundGeolocation.on(BackgroundGeolocationEvents.location).subscribe((location: BackgroundGeolocationResponse) => {
          console.log('back pos', location);

          // IMPORTANT:  You must execute the finish method here to inform the native plugin that you're finished,
          // and the background-task may be completed.  You must do this regardless if your operations are successful or not.
          // IF YOU DON'T, ios will CRASH YOUR APP for spending too much time in the background.
          this.backgroundGeolocation.finish(); // FOR IOS ONLY
        });

      });

    // start recording location
    this.backgroundGeolocation.start();

    // If you wish to turn OFF background-tracking, call the #stop method.
  }
  backgroundActivity() {
    let intervalCall: any;
    if (this.platform.is('cordova')) {
      //Subscribe on pause i.e. background
      this.platform.pause.subscribe(() => {
        // this.globals.appInBackground = true;
        intervalCall = setInterval(() => {
          console.log('hello from background')
          this.backgroundLocate();

          // if(this.globals.shiftStatus){
          // this.getLocation();
          // }
        }, 10000)
        console.log('paused platform');
        //Hello pause
      });

      //Subscribe on resume i.e. foreground
      this.platform.resume.subscribe(() => {
        this.backgroundGeolocation.stop();

        clearInterval(intervalCall);

        // this.globals.appInBackground = false;
        console.log('resumed app platform');
        window['paused'] = 0;
      });
    }
    // this.background.enable();
    // this.background.excludeFromTaskList();
    // this.background.overrideBackButton();

  }
  backgroundMode() {
    if (this.background.isEnabled()) {
      console.log('enabled background mode');

    }
    else {
      console.log('not anabled.background mode');
      this.background.enable();
    }

    this.background.enable();
    // this.backgroundMode.excludeFromTaskList();
    this.background.on('activate').subscribe(() => {
      console.log('background mode active')

      // this.backgroundMode.isActive()
      this.background.overrideBackButton();
      // this.backgroundMode.isScreenOff()
      this.background.disableWebViewOptimizations()

    });

  }
  getLocation() {
    this.geoLocation.getCurrentPosition().then((data: any) => {
      console.log(data)
      let coordinates = data.coords.latitude + ',' + data.coords.longitude;
      console.log(coordinates)
      this.server.udateLocation(coordinates).subscribe(res => {
        console.log(res)
      }, err => {
        console.log(err)
      })

    });


  }
  getLoginInfo() {
    if (localStorage.getItem('user') == 'null' || localStorage.getItem('user') == null) {
      this.router.navigateByUrl('login', { replaceUrl: true });
    }
    else {
      this.globals.riderData = JSON.parse(localStorage.getItem('user'))
      this.router.navigateByUrl('home', { replaceUrl: true })

    }
  }
}
