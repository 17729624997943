import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServerService } from './services/server.service';
import { SharedService } from './services/shared.service';
import { HttpClientModule } from '@angular/common/http';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { BackgroundGeolocation, } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule
  ,ServerService,
  SharedService,
  HttpClientModule,

],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LaunchNavigator,
    BackgroundMode,
    BackgroundGeolocation,
    CallNumber,
    Geolocation],
  bootstrap: [AppComponent],
})
export class AppModule {}
