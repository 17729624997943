import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
@NgModule()
export class ServerService {
  constructor(private http: HttpClient,
    private globals: SharedService,
  ) {

  }
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public login(values): Observable<any> {
    // let form = new FormData()
    // form.append('password', values.password);
    // form.append('email', values.email);
    let date = JSON.stringify({
      "email": values.email,
      "password": values.password
    })
    let path = this.globals.baseUrl + "driver_controller/driverLogin";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public getShiftStatus(): Observable<any> {
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
    })
    let path = this.globals.baseUrl + "driver_controller/verifyDriverShift";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }

  public updateShiftStatus(status): Observable<any> {
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "action": status,
    })
    let path = this.globals.baseUrl + "driver_controller/updateDriverShift";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public acceptOrder(order_id,type):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "order_id":order_id,
      "order_type":type
    })
    let path = this.globals.baseUrl + "driver_controller/acceptOrder";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public changeOrderStatus(status,order_id,type):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "status": status,
      "order_id":order_id,
      "order_type":type
    })
    let path = this.globals.baseUrl + "driver_controller/changeOrderStatus";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }

  public getOrders(status):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "status": status,
    })
    let path = this.globals.baseUrl + "driver_controller/getDriverOrders";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public udateLocation(coords):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "location": coords,
    })
    let path = this.globals.baseUrl + "driver_controller/updateDriverLocation";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public dayClose(coords):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "driver_id": this.globals.riderData.id,
      "location": coords,
    })
    let path = this.globals.baseUrl + "Business_stats_controller/close_business_day";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  public getShiftReport(startDate:any, endDate:any):Observable<any>{
    let date = JSON.stringify({
      "business_id": this.globals.riderData.business_id,
      "staff_id": this.globals.riderData.id,
      "start": startDate,
      "end": endDate,
    })
    let path = this.globals.baseUrl + "rider_controller/get_rider_report";
    return this.http
      .post<any>(path, date)
      .pipe(catchError(this.handleError));
  }
  
}
